var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"settings page"},[_c('div',{staticClass:"settings-content"},[_c('div',{staticClass:"settings-body"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t('settings.title'))+" ")]),_c('p',[_c('strong',[_vm._v(" "+_vm._s(_vm.$t('settings.logo'))+" ")])]),(_vm.organisation.has_avatar)?_c('div',{staticClass:"logo-wrapper"},[_c('img',{attrs:{"src":_vm.organisationLogoPath}})]):_c('p',{staticClass:"no-logo"},[_vm._v(" "+_vm._s(_vm.$t('settings.no_logo'))+" ")]),_c('p',[_c('button',{staticClass:"button set-logo-button",on:{"click":_vm.showAvatarModal}},[_vm._v(" "+_vm._s(_vm.$t('settings.set_logo'))+" ")])]),_c('text-field',{staticClass:"mt2",attrs:{"label":_vm.$t('settings.fields.name')},on:{"enter":function($event){return _vm.saveSettings()}},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('text-field',{attrs:{"label":_vm.$t('settings.fields.hours_by_day'),"type":"number"},on:{"enter":function($event){return _vm.saveSettings()}},model:{value:(_vm.form.hours_by_day),callback:function ($$v) {_vm.$set(_vm.form, "hours_by_day", $$v)},expression:"form.hours_by_day"}}),_c('combobox-boolean',{attrs:{"label":_vm.$t('settings.fields.use_original_name')},on:{"enter":function($event){return _vm.saveSettings()}},model:{value:(_vm.form.use_original_file_name),callback:function ($$v) {_vm.$set(_vm.form, "use_original_file_name", $$v)},expression:"form.use_original_file_name"}}),_c('h2',[_vm._v(" "+_vm._s(_vm.$t('settings.integrations'))+" ")]),_c('text-field',{attrs:{"label":_vm.$t('settings.fields.slack_token')},on:{"enter":function($event){return _vm.saveSettings()}},model:{value:(_vm.form.chat_token_slack),callback:function ($$v) {_vm.$set(_vm.form, "chat_token_slack", $$v)},expression:"form.chat_token_slack"}}),_c('button',{class:{
          button: true,
          'save-button': true,
          'is-medium': true,
          'is-loading': _vm.loading.save
        },on:{"click":function($event){return _vm.saveSettings()}}},[_vm._v(" "+_vm._s(_vm.$t('settings.save.button'))+" ")]),_c('p',{class:{
          error: true,
          'is-hidden': !_vm.errors.save
        }},[_vm._v(" "+_vm._s(_vm.$t('settings.save.error'))+" ")])],1)]),_c('change-avatar-modal',{attrs:{"active":_vm.modals.avatar,"is-loading":_vm.loading.saveAvatar,"is-error":_vm.errors.saveAvatar,"title":_vm.$t('settings.change_logo')},on:{"confirm":_vm.uploadAvatarFile,"cancel":_vm.hideAvatarModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }